import React, { useState } from 'react';
import { InputContainer, FormInput, TextArea, SubmitButton, Title } from './styles';
import { sendTicketForm } from '../../service/apiService';
import BottomNavigation from '../../components/BottomNavigation';
import { formatPhoneNumber } from '../../utils/phone';
import AppContainerComponent from '../../components/appContainer';
import ContentBox from '../../components/contentBox';
import SocialMediaBox from '../../components/socialMediaBox';
import Popup from '../../components/popup';

const Contact: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({ title: '', subtitle: '' });

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhone(formattedPhone);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const resultStatus = await sendTicketForm(name, email, phone, subject, message);

    if (resultStatus === 201) {
      setPopupData({
        title: "Sucesso!",
        subtitle: "Seu contato foi enviado com sucesso"
      })

      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
      setSubject('');
    } else {
      setPopupData({
        title: "Erro!",
        subtitle: "Infelizmente houve um erro no envio, tente novamente mais tarde"
      })
    }

    setShowPopup(!showPopup);

  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <AppContainerComponent>
      <ContentBox>
        <Title>Fale conosco</Title>
        <form onSubmit={handleSubmit}>
          <InputContainer>
            <label>Nome</label>
            <FormInput
              placeholder="Seu nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </InputContainer>
          <InputContainer>
            <label>Email</label>
            <FormInput
              type="email"
              placeholder="Seu e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </InputContainer>
          <InputContainer>
            <label>Celular</label>
            <FormInput
              type="tel"
              placeholder="Ex.: 99 999999999"
              value={phone}
              onChange={(e) => handlePhoneChange(e)}
              required
            />
          </InputContainer>
          <InputContainer>
            <label>Assunto</label>
            <FormInput
              placeholder="Ex.: Não consigo baixar meu video"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </InputContainer>
          <InputContainer>
            <label>Mensagem</label>
            <TextArea
              placeholder="Sua mensagem"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </InputContainer>
          <InputContainer>
            <SubmitButton type="submit">Enviar</SubmitButton>
          </InputContainer>
        </form>

        <SocialMediaBox title='Ou use uma de nossas redes sociais' />
        <BottomNavigation />

      </ContentBox>

      {showPopup && <Popup onClose={togglePopup} title={popupData.title} subtitle={popupData.subtitle} />
      }
    </AppContainerComponent>
  );
};

export default Contact;
