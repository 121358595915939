import React from 'react';
import { PopupContainer, CloseButton, PopupOverlay } from './styles';

interface AppContainerProps {
  onClose: (event?: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void; // Tipagem correta para a função onClose
  title: string;
  subtitle?: string;
}

const Popup: React.FC<AppContainerProps> = ({ onClose, title, subtitle }) => {
  return (
    <PopupOverlay onClick={onClose}>
      <PopupContainer onClick={(e) => e.stopPropagation()}> {/* Evita que o clique dentro do popup feche o overlay */}
        <p style={{ color: "black", fontWeight:'bold', fontSize:"18px" }}>{title}</p>
        {subtitle && <h4 style={{fontWeight:'normal'}}>{subtitle}</h4>}
        <CloseButton onClick={onClose}>Fechar</CloseButton>
      </PopupContainer>
    </PopupOverlay>
  );
};

export default Popup;
