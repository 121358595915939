import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { fetchVideosByField } from '../../service/apiService';
import { Field, Video } from '../../types/types';
import { sortDays, sortHours, timestampToHM } from '../../utils/date';

import thumbnailDefault from './../../assets/campo.png'

import { VideoItem, FieldInfo, FiltersContainer, FilterButton, FiltersBox, BackButton, VideoListContainer } from './styles';
import BottomNavigation from '../../components/BottomNavigation';
import AppContainerComponent from '../../components/appContainer';
import ContentBox from '../../components/contentBox';

const FieldDetails: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { field } = location.state as { field: Field };
    const [videos, setVideos] = useState<Video[]>([]);
    const [loading, setLoading] = useState(true);

    const [selectedCourt, setSelectedCourt] = useState<string | null>(null);
    const [selectedDay, setSelectedDay] = useState<string | null>(null);
    const [selectedHour, setSelectedHour] = useState<string | null>(null);

    const [courts, setCourts] = useState<string[]>([]);
    const [days, setDays] = useState<string[]>([]);
    const [hours, setHours] = useState<string[]>([]);

    const isAppleDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    console.log("------------")
    console.log("isAppleDevice", isAppleDevice)
    console.log("------------")

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const videoData = await fetchVideosByField(field.id);
                setVideos(videoData);

                const uniqueCourts = Array.from(new Set(videoData.map(video => video.court)));

                setCourts(uniqueCourts);
                setSelectedCourt(uniqueCourts[0])

                const uniqueDays = Array.from(new Set(videoData.map(video => video.day)));

                setDays(sortDays(uniqueDays));
                setSelectedDay(sortDays(uniqueDays)[0])

                const uniqueHours = Array.from(new Set(videoData.map(video => video.hour)));

                setHours(sortHours(uniqueHours));
                setSelectedHour(sortHours(uniqueHours)[0])


            } catch (error) {
                console.error('Erro ao buscar vídeos:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVideos();
    }, [field.name]);

    const filteredVideos = videos.filter(video => {
        return (!selectedCourt || video.court === selectedCourt) &&
            (!selectedDay || video.day === selectedDay) &&
            (!selectedHour || video.hour === selectedHour);
    });

    const handleVideoClick = (video: Video) => {
        navigate(`/video-details/${video.id}`, { state: { video, field } });
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <AppContainerComponent>
            <ContentBox>

                <FieldInfo>
                    <img src={field.logoUrl} alt={`${field.name} logo`} style={{ width: 50, height: 50, marginRight: 10 }} />
                    <div>
                        <h2>{field.name}</h2>
                        <BackButton onClick={handleBackClick}>
                            &#8592;
                        </BackButton>
                    </div>
                </FieldInfo>

                <FiltersContainer>
                    <FiltersBox>
                        {courts.length > 0 && (
                            <>
                                {courts.map(court => (
                                    <FilterButton
                                        key={court}
                                        onClick={() => setSelectedCourt(court)}
                                        active={selectedCourt === court}
                                    >
                                        {court}
                                    </FilterButton>
                                ))}
                            </>
                        )}
                    </FiltersBox>

                    <FiltersBox>
                        {days.length > 0 && (
                            <>
                                {days.map(day => (
                                    <FilterButton
                                        key={day}
                                        onClick={() => setSelectedDay(day)}
                                        active={selectedDay === day}
                                    >
                                        {day}
                                    </FilterButton>
                                ))}
                            </>
                        )}
                    </FiltersBox>

                    <FiltersBox>
                        {hours.length > 0 && (
                            <>
                                {hours.map(hour => (
                                    <FilterButton
                                        key={hour}
                                        onClick={() => setSelectedHour(hour)}
                                        active={selectedHour === hour}
                                    >
                                        {hour}
                                    </FilterButton>
                                ))}
                            </>
                        )}
                    </FiltersBox>
                </FiltersContainer>

                <VideoListContainer>
                    {loading ? (
                        <p style={{ margin: 'auto', color: "black" }}>Carregando vídeos...</p>
                    ) : (
                        filteredVideos.map((video) => (
                            <>
                                <VideoItem key={video.id} onClick={() => handleVideoClick(video)}>
                                    {/* <video width="100%" height="100px" muted preload="metadata" poster={video.thumbnailUrl ? video.thumbnailUrl : thumbnailDefault}>
                                        <source src={video.url} type="video/mp4" />
                                        Seu navegador não suporta a reprodução de vídeo.
                                    </video> */}

                                    <img width={'100%'} src={video.thumbnailUrl ? video.thumbnailUrl : thumbnailDefault} alt="" />
                                    <div>
                                        <text>{timestampToHM(video.createdAt)}</text>
                                    </div>
                                </VideoItem>

                            </>

                        ))
                    )}
                </VideoListContainer>

                <BottomNavigation />
            </ContentBox>
        </AppContainerComponent>
    );
};

export default FieldDetails;
