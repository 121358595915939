import { Video } from '../types/types';
import axios from 'axios';

export const fetchFootballFields = async (): Promise<any[]> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/fields/all`);

    return response.data;
  } catch (error) {
    console.error('Erro ao buscar os campos de futebol:', error);
    throw error;
  }
};

export const fetchVideosByField = async (fieldId: number): Promise<Video[]> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/videos/all/${fieldId}`);

    console.log("---------")
    console.log("response", response)
    console.log("---------")

    return response.data
  } catch (error) {
    console.error('Erro ao buscar vídeos por campo:', error);
    throw error;
  }
};

export const sendTicketForm = async (name: string, email: string, phone: string, subject: string, message: string) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/tickets/`, { name, email, subject, phone, message });
    console.log('------------------')
    console.log('response', response)
    console.log('name', name)
    console.log('email', email)
    console.log('phone', phone)
    console.log('message', message)
    console.log('------------------')
    return response.status;
  } catch (error) {
    console.error('Erro ao enviar o formulário de contato:', error);
    return 400;
  }
};

