import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header, VideoContainer, VideoPlayer, DownloadButton, BackButton, FieldInfo } from './styles';
import { Field, Video } from '../../types/types';
import BottomNavigation from '../../components/BottomNavigation';
import AppContainerComponent from '../../components/appContainer';
import ContentBox from '../../components/contentBox';
import SocialMediaBox from '../../components/socialMediaBox';

const VideoDetails: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { video, field } = location.state as { video: Video, field: Field };

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <AppContainerComponent>
            <ContentBox>
                <Header>
                    <FieldInfo>
                        <img src={field.logoUrl} alt="Logo" style={{ width: 50, height: 50, marginRight: 10 }} />
                        <h2>{field.name}</h2>
                    </FieldInfo>
                    <BackButton onClick={handleBackClick}>
                        &#8592;
                    </BackButton>
                </Header>
                <VideoContainer>
                    <VideoPlayer controls autoPlay>
                        <source src={video.downloadUrl} type="video/mp4" />
                        Seu navegador não suporta a reprodução de vídeo.
                    </VideoPlayer>
                    <DownloadButton href={video.downloadUrl} download>
                        Download
                    </DownloadButton>
                </VideoContainer>
                <SocialMediaBox
                    title='Quer ver seus videos em destaque?'
                    subtitle='nos envie através das nossas redes sociais!'
                />
                <BottomNavigation />
            </ContentBox>
        </AppContainerComponent>
    );
};

export default VideoDetails;
